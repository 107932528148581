import React from 'react'
import { arrayCheck } from '../../arrayCheck'
//components
import { CheckMarkView } from '../CheckMarkVIew/CheckMarkView'
import { StepOneContainer } from '../StepOne/StepOneContainer'
import { StepTwoContainer } from '../StepTwo/StepTwoContainer'
import StepThreeContainer from '../StepThree/StepThreeContainer'
//styles images
import done from '../../assets/done.svg'
import PropTypes from 'prop-types'

export const MainModal = ({
  isConfirm,
  step,
  onClickConfirm,
  setIsSavedToken,
  choiceDate,
  setChoiceDate,
  getDateIsError,
  getDate,
  confirmFunc,
  isErrorCreate,
  dataCreate,
  reaction,
  isFromAnotherWeb,
  onClickButtonWidget
}) => {

  const renderContent = () => {
    switch (step) {
      case 1:
        return <StepOneContainer onClickConfirm={onClickConfirm} setIsSavedToken={setIsSavedToken}/>
      case 2:
        return <StepTwoContainer onClickConfirm={onClickConfirm} choiceDate={choiceDate} setChoiceDate={setChoiceDate}
                                 getDate={getDate} getDateIsError={getDateIsError}/>
      case 3:
        return <StepThreeContainer confirmFunc={confirmFunc} choiceDate={choiceDate} isErrorCreate={isErrorCreate}
                                   dataCreate={dataCreate} reaction={reaction}/>
      default:
        return null
    }
  }

  if (isConfirm) return (
    <div className="mainModal" style={{ padding: 0 }}>
      <div className="viewDone">
        <img src={done} className="imageDone"/>
      </div>
      <div className="mainTextModal">You're all set!</div>
      <div style={{
        marginBottom: 24,
        padding: 24
      }}>
        <div className="textDone">Thanks for registering for our
          Grouport Series.
        </div>
        <div className="textDone">Please be on the lookout for a confirmation email in your inbox with your group
          details, and
          you'll also receive weekly reminder emails 24 hours before each session containing the unique link to join
          that
          week's session.
        </div>
        <div className="textDone">If you have any questions feel free to email us at support@grouporttherapy.com at any
          time and a
          support representative will get back to you shortly.
        </div>
      </div>
    </div>
  )

  return (
    <div className="mainModal">
      <div className="mainTextModal">Join a Dialectical Behavior Therapy (DBT) Skills Group!</div>
      <div className="mainSecondTextModal">It’s time to improve your relationships, reduce emotional distress, and
        rediscover hope for the future by taking the first step in your journey toward total mastery of DBT skills.
      </div>
      <div style={{ marginTop: 8 }}>
        {arrayCheck.map(e => <CheckMarkView key={e.text} text={e.text}/>)}
      </div>

      {isFromAnotherWeb ?
        <div className="viewWidget">
          <div className={`buttonWidget ${step === 2 ? 'active' : ''}`}
               onClick={() => step === 1 || step === 2 ? {} : onClickButtonWidget(2)}>
            <p>Choose Your Group</p>
          </div>
          <div className={`buttonWidgetLast ${step === 3 ? 'active' : ''}`}>
            <p>Confirm Payment</p>
          </div>
        </div> : <div className="viewWidget">
          <div className={`buttonWidget ${step === 1 ? 'active' : ''}`}
               onClick={() => {}}>
            <p>Your Info</p>
          </div>
          <div className={`buttonWidget ${step === 2 ? 'active' : ''}`}
               onClick={() => step === 1 || step === 2 ? {} : onClickButtonWidget(2)}>
            <p>Choose Your group</p>
          </div>
          <div className={`buttonWidgetLast ${step === 3 ? 'active' : ''}`}>
            <p>Confirm Payment</p>
          </div>
        </div>}
      {renderContent()}
    </div>
  )
}

MainModal.propTypes = {
  step: PropTypes.number,
  choiceDate: PropTypes.object,
  dataCreate: PropTypes.object,
  getDate: PropTypes.object,
  getDateIsError: PropTypes.bool,
  isErrorCreate: PropTypes.bool,
  isConfirm: PropTypes.bool,
  reaction: PropTypes.bool,
  token: PropTypes.string,
  onClickButtonWidget: PropTypes.func,
  onClickConfirm: PropTypes.func,
  setIsSavesToken: PropTypes.func,
  setChoiceDate: PropTypes.func,
  confirmFunc: PropTypes.func,
}

