export async function StorageGetItem (key) {
  try {
    const value = sessionStorage.getItem(key);
    if (value !== undefined) {
      return value;
    }
  } catch (error) {
    return error;
  }
}

export async function StorageSetItem (key, value) {
  try {
    await sessionStorage.setItem(key, value);
    return true;
  } catch (error) {
    return error;
  }
}

export async function StorageClearAll () {
  try {
    await sessionStorage.clear();
  } catch (error) {
    return error;
  }
}
