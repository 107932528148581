import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
//utils services
import { addSeries, checkEmail, editProfile, getCountries, login, registration } from '../../services/auth'
import { StorageSetItem } from '../../utils/storage'
import { IS_EDIT, STORED_ACCESS_TOKEN } from '../../utils/constants'
import { isValidEmail } from '../../tools/validations'
//components
import { StepOne } from './StepOne'
//styles images
import './style.css'
import 'react-phone-input-2/lib/style.css'
import PropTypes from 'prop-types'

export const StepOneContainer = ({ onClickConfirm, setIsSavedToken }) => {
  const { data: dataAdd, isError: isErrorAdd, mutate: mutateAdd } = useMutation(addSeries)
  const { data: dataEdit, isError: isErrorEdit, mutate: mutateEdit } = useMutation(editProfile)
  const { data: dataGetCountries, isError: isErrorGetCountries, mutate: mutateGetCountries } = useMutation(getCountries)
  const { data: dataLogin, isError: isErrorLogin, mutate: mutateLogin } = useMutation(login)
  const { data: dataCheckEmail, isError: isErrorCheckEmail, mutate: mutateCheckEmail } = useMutation(checkEmail)
  const { data, isError, mutate } = useMutation(registration)
  const [spinner, setSpinner] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [allCountries, setAllCountries] = useState([])
  const [isFoundError, setIsFoundError] = useState(false)
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    age: '',
    state: '',
    country: '',
    iam18: false,
  })
  let isValidationCorrect = state.firstname.length > 0 &&
    state.lastname.length > 0 &&
      (state.phone.length >= 11 && state.phone.length <= 15) &&
    state.age > 18 &&
    state.state && state.state !== 'Select Region'
    && state.iam18 === true
  isValidEmail(state.email)

  useEffect(() => {
    if (data && !isError || dataLogin && !isErrorLogin) {
      signInFunc()
      setIsSavedToken(true)
    }
  }, [data, dataLogin])

  useEffect(() => {
    if (dataAdd && !isErrorAdd) {
      onClickConfirm(2)
      setSpinner(false)
    }
  }, [dataAdd])

  useEffect(() => {
    mutateGetCountries()
  }, [])

  useEffect(() => {
    if (dataGetCountries?.data?.data?.items) {
      const selectCountry = { id: 300, code: 'Select Country', name: 'Select Country' }
      const countries = dataGetCountries?.data?.data?.items
      countries.unshift(selectCountry)
      setAllCountries(countries)
    }
  }, [dataGetCountries])

  useEffect(() => {
    if (state.state !== 'Outside of the US') {
      setState({ ...state, country: '' })
    }
  }, [state.state])

  useEffect(() => {
    if (dataCheckEmail && !isErrorCheckEmail) {
      if (!dataCheckEmail.data.data.item?.customer) {
        setIsModal(true)
        setSpinner(false)
      } else {
        if (!dataCheckEmail.data.data.item?.isExists) {
          mutate({ firstname: state.firstname, lastname: state.lastname, email: state.email })
        } else {
          if (dataCheckEmail.data.data.item?.paid) {
            setIsModal(true)
            setSpinner(false)
          } else {
            mutateLogin({ email: state.email, firstname: state.firstname, lastname: state.lastname })
          }
        }
      }

    }
  }, [dataCheckEmail])

  useEffect(() => {
    if (isErrorCheckEmail || isErrorLogin || isError || isErrorAdd || isErrorEdit) {
      setSpinner(false)
    }
  }, [isErrorCheckEmail, isErrorLogin, isError, isErrorAdd, isErrorEdit])

  const signInFunc = async () => {
    const token = data?.data?.data?.token || dataLogin?.data?.data?.token
    await StorageSetItem(STORED_ACCESS_TOKEN, token)
    if (data) {
      const numberWithoutFirstFigure = state.phone.toString().substring(1)
      mutateAdd({
        phone: numberWithoutFirstFigure,
        age: state.age,
        country: state.country || 'United States',
        state: state.state !== 'Outside of the US' ? state.state : ''
      })
    } else {
      mutateEdit({ data: state })
      await StorageSetItem(IS_EDIT, true)
      onClickConfirm(2)
      setSpinner(false)
    }
  }

  const changeHandler = (name, value) => {
    if (name === 'age') {
      if (value.length < 3) {
        setState(prevState => ({
          ...prevState,
          [name]: +value,
        }))
      }
    } else {
      setState(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const onClickContinue = () => {
    setSpinner(true)
    setIsFoundError(true)
    if (isValidationCorrect) {
      if (state.state !== 'Outside of the US') {
        mutateCheckEmail({ email: state.email })
      } else {
        if (state.state === 'Outside of the US' && state.country && state.country !== 'Select Country') {
          mutateCheckEmail({ email: state.email })
        } else {
          setSpinner(false)
        }
      }
    } else {
      setSpinner(false)
    }
  }

  const closeModal = () => {
    setIsModal(false)
  }

  return (
    <StepOne
      allCountries={allCountries}
      changeHandler={changeHandler}
      isFoundError={isFoundError}
      isModal={isModal}
      closeModal={closeModal}
      setIsModal={setIsModal}
      onClickContinue={onClickContinue}
      setState={setState}
      state={state}
      spinner={spinner}/>
  )
}

StepOneContainer.propTypes = {
  onClickConfirm: PropTypes.func,
  setIsSavesToken: PropTypes.func
}
