import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { StepThree } from './StepThree'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'

const StepThreeContainer = ({ confirmFunc, choiceDate, dataCreate, isErrorCreate, reaction }) => {
  const stripePromise = loadStripe(reaction ? process.env.REACT_APP_STRIPE_SECRET_TEST_KEY :
    process.env.REACT_APP_STRIPE_SECRET_KEY)

  return (
    <Elements stripe={stripePromise} options={{ locale: 'en' }}>
      <StepThree confirmFunc={confirmFunc} choiceDate={choiceDate} isErrorCreate={isErrorCreate}
                 dataCreate={dataCreate}/>
    </Elements>
  )
}

export default StepThreeContainer

StepThreeContainer.propTypes = {
  reaction: PropTypes.bool,
}
