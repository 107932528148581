import './App.css'
import { MainContainer } from './screen/MainContainer'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { StorageClearAll } from './utils/storage'

const queryClient = new QueryClient()

const App = () => {
  const [isStart, setIsStart] = useState(false)
  useEffect(() => {
    // deleteStorage()
    setIsStart(true)
  }, [])

  const deleteStorage = async () => {
    if (performance.navigation.type === 1) {
      console.log('This page is reloaded')
    } else {
      console.log('deleteStorage')
      await StorageClearAll()
    }
    setIsStart(true)
  }

  return (
    <QueryClientProvider client={queryClient}>
      {isStart && <MainContainer/>}
    </QueryClientProvider>

  )
}

export default App
