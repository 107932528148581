import axios from 'axios'
import { StorageGetItem } from './storage'
import { STORED_ACCESS_TOKEN } from './constants'

let repeatCount = 0

const http = async () => {
  const config = process.env.REACT_APP_API_URL
  let header = {
    'Content-type': 'application/json',
  }
  const token = await StorageGetItem(STORED_ACCESS_TOKEN)
  console.log('token', token)
  if (token) {
    header.Authorization = `Bearer ${token}`
  }

  const authorizedInstance = axios.create({
    baseURL: config,
    headers: header,
  })

  authorizedInstance.interceptors.request.use(function (config) {
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  authorizedInstance.interceptors.response.use(async (response) => {
    return response
  }, async (error) => {
    const { response } = error
    if (response) {
      if (response.status === 401 && repeatCount === 0) {
        // window.location.href = process.env.REACT_APP_DOMEN
      }
    }
    return Promise.reject(error)
  })

  return authorizedInstance
}

export default http
