import React from 'react'

export const RegistrationField = ({ name, label, changeHandler, value, isError, isModal, disabled }) => {
  const onKeyPress = () => {
    return name === 'age' ? (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault()
      }
    } : {}
  }

  return (
    <div className="input-field">
      <div className="input-field__input">
        <label htmlFor={name} className={isError ? 'input-field__labelError' : 'input-field__label'}
               style={{ zIndex: !isModal ? 1 : 0 }}>
          {label}
        </label>
        <input className={isError ? 'inputError' : 'input'} value={value} disabled={disabled}
               onChange={(e) => changeHandler(name, e.target.value)}
               onKeyPress={onKeyPress}
        />
      </div>
    </div>
  )
}
