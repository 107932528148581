import React, { useEffect, useState } from 'react'
import './style.css'
import { StepTwo } from './StepTwo'
import PropTypes from 'prop-types'

export const StepTwoContainer = ({ onClickConfirm, setChoiceDate, choiceDate, getDateIsError, getDate }) => {
  const [viewArray, setViewArray] = useState([])
  const [choiceError, setChoiceError] = useState(false)

  useEffect(() => {
    if (getDate && !getDateIsError) {
      setViewArray(getDate.data.data.item)
    }
  }, [getDate])

  const onClickChoice = (e) => {
    if (e.id === choiceDate.id) {
      setChoiceDate({})
    } else {
      setChoiceDate(e);
    }

    setChoiceError(false)
  }

  const onClickNext = () => {
    if (Object.keys(choiceDate).length) {
      onClickConfirm(3)
    } else {
      setChoiceError(true)
    }
  }

  return (
    <StepTwo
      choiceDate={choiceDate}
      onClickNext={onClickNext}
      onClickChoice={onClickChoice}
      viewArray={viewArray}
      choiceError={choiceError}
    />
  )
}

StepTwoContainer.propTypes = {
  onClickConfirm: PropTypes.func,
  setChoiceDate: PropTypes.func,
  choiceDate: PropTypes.object,
  getDate: PropTypes.object,
  getDateIsError: PropTypes.bool
}
