import React from 'react'
import { MainModalContainer } from '../components/MainModal/MainModalContainer'
import './style.css'

export const MainContainer = () => {

  return (
    <div className="mainContainer">
      <div className="headerContainer">
        <div className="simple-logo"/>
      </div>
      <div className="viewForModal">
        <MainModalContainer/>
      </div>
    </div>
  )
}
