import React from 'react'
import './style.css'

const InputField = ({ children, name, label, isError}) => {
  return (
    <div className="input-field">
      <div className='input-field__input'>
        <label htmlFor={name} className={isError?'input-field__labelError':'input-field__label'}>
          {label}
        </label>
        {children}
      </div>
    </div>
  )
}

export default InputField
