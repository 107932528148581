import React from 'react'
import check_mark from '../../assets/check_mark.svg'

export const CheckMarkView = ({ text }) => {
  return (
    <div style={{ marginTop: 12, display: 'flex' }}>
      <img src={check_mark} style={{ height: 35, width: 35 }}/>
      <div style={{
        marginLeft: 8,
        display: 'flex', alignItems: 'center', color: '#3d5581',
        fontWeight: '400',
        fontSize: 16,
        fontFamily: 'Europa'
      }}
      >{text}
      </div>
    </div>
  )
}
