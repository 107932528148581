import React, { CSSProperties } from 'react'
import { RegistrationField } from '../RegistrationField/RegistrationField'
import { isValidEmail } from '../../tools/validations'
import InputField from '../InputField/InputField'
import PhoneInput from 'react-phone-input-2'
import { CountryDropdown } from 'react-country-region-selector'
import ClipLoader from 'react-spinners/ClipLoader'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

export const StepOne = ({
  changeHandler,
  isFoundError,
  isModal,
  spinner,
  setState,
  onClickContinue,
  setIsModal,
  closeModal,
  state,
  allCountries
}) => {
  const options = [
    { id: 99, value: 'Select Region', label: 'Select Region' },
    { id: 0, value: 'Outside of the US', label: 'Outside of the US' },
    { id: 1, value: 'AL', label: 'Alabama' },
    { id: 2, value: 'AK', label: 'Alaska' },
    { id: 3, value: 'AZ', label: 'Arizona' },
    { id: 4, value: 'AR', label: 'Arkansas' },
    { id: 5, value: 'CA', label: 'California' },
    { id: 6, value: 'CO', label: 'Colorado' },
    { id: 7, value: 'CT', label: 'Connecticut' },
    { id: 8, value: 'DE', label: 'Delaware' },
    { id: 9, value: 'DC', label: 'District Of Columbia' },
    { id: 10, value: 'FL', label: 'Florida' },
    { id: 11, value: 'GA', label: 'Georgia' },
    { id: 12, value: 'HI', label: 'Hawaii' },
    { id: 13, value: 'ID', label: 'Idaho' },
    { id: 14, value: 'IL', label: 'Illinois' },
    { id: 15, value: 'IN', label: 'Indiana' },
    { id: 16, value: 'IA', label: 'Iowa' },
    { id: 17, value: 'KS', label: 'Kansas' },
    { id: 18, value: 'KY', label: 'Kentucky' },
    { id: 19, value: 'LA', label: 'Louisiana' },
    { id: 20, value: 'ME', label: 'Maine' },
    { id: 21, value: 'MD', label: 'Maryland' },
    { id: 22, value: 'MA', label: 'Massachusetts' },
    { id: 23, value: 'MI', label: 'Michigan' },
    { id: 24, value: 'MN', label: 'Minnesota' },
    { id: 25, value: 'MS', label: 'Mississippi' },
    { id: 26, value: 'MO', label: 'Missouri' },
    { id: 27, value: 'MT', label: 'Montana' },
    { id: 28, value: 'NE', label: 'Nebraska' },
    { id: 29, value: 'NV', label: 'Nevada' },
    { id: 30, value: 'NH', label: 'New Hampshire' },
    { id: 31, value: 'NJ', label: 'New Jersey' },
    { id: 32, value: 'NM', label: 'New Mexico' },
    { id: 33, value: 'NY', label: 'New York' },
    { id: 34, value: 'NC', label: 'North Carolina' },
    { id: 35, value: 'ND', label: 'North Dakota' },
    { id: 36, value: 'OH', label: 'Ohio' },
    { id: 37, value: 'OK', label: 'Oklahoma' },
    { id: 38, value: 'OR', label: 'Oregon' },
    { id: 39, value: 'PA', label: 'Pennsylvania' },
    { id: 40, value: 'RI', label: 'Rhode Island' },
    { id: 41, value: 'SC', label: 'South Carolina' },
    { id: 42, value: 'SD', label: 'South Dakota' },
    { id: 43, value: 'TN', label: 'Tennessee' },
    { id: 44, value: 'TX', label: 'Texas' },
    { id: 45, value: 'UT', label: 'Utah' },
    { id: 46, value: 'VT', label: 'Vermont' },
    { id: 47, value: 'VA', label: 'Virginia' },
    { id: 48, value: 'WA', label: 'Washington' },
    { id: 49, value: 'WV', label: 'West Virginia' },
    { id: 50, value: 'WI', label: 'Wisconsin' },
    { id: 51, value: 'WY', label: 'Wyoming' }
  ]

  return (
    <>
      <div className="mainViewStepOne">
        <div className="form-edit">
          <RegistrationField name="firstname" label="First Name" changeHandler={changeHandler} value={state.firstname}
                             isError={isFoundError && state.firstname.length === 0} isModal={isModal}/>
          <RegistrationField name="lastname" label="Last Name" changeHandler={changeHandler} value={state.lastname}
                             isError={isFoundError && state.lastname.length === 0} isModal={isModal}/>
          <RegistrationField name="email" label="Email" changeHandler={changeHandler} value={state.email}
                             isError={isFoundError && !isValidEmail(state.email)} isModal={isModal}/>
          <RegistrationField name="phone" label="Phone" isFoundError={isFoundError}
                             isError={isFoundError && state.phone.length < 11} isModal={isModal}/>
          <InputField>
            <PhoneInput
              containerClass="containerPhoneInput"
              inputClass="inputPhoneInput"
              specialLabel={''}
              buttonStyle={{ display: 'none' }}
              country="us"
              value={state.phone}
              countryCodeEditable={true}
              enableAreaCodes={true}
              disableDropdown
              disableSearchIcon={true}
              onChange={(string) => changeHandler('phone', string)}/>
          </InputField>
          <div style={{ marginTop: -25 }}/>
          <RegistrationField name="age" label="Age" changeHandler={changeHandler} value={state.age}
                             isError={isFoundError && state.age < 18} isModal={isModal}/>
          <RegistrationField name="location" label="Location"
                             isError={isFoundError && !state.state || isFoundError && state.state === 'Select Region'}
                             isModal={isModal}
                             disabled/>
          <InputField>
            <select className={'select'}
                    onChange={(el) => setState({ ...state, state: el.target.value })}>
              {options.map((e) => <option
                value={e.label}>{e.label}</option>)}
            </select>
          </InputField>
          <div style={{ marginTop: -25 }}/>
          {state.state === 'Outside of the US' &&
            <>
              <RegistrationField name="country" label="Country" isError={isFoundError && !state.country || isFoundError && state.country === 'Select Country'}
                                 isModal={isModal}
                                 disabled/>
              <InputField>
                <select className={'select'}
                        onChange={(el) => setState({ ...state, country: el.target.value })}>
                  {allCountries?.map((e) => <option
                    value={e.name}>{e.name}</option>)}
                </select>
              </InputField>
              <div style={{ marginTop: -25 }}/>
            </>}
          {/*{state.state === 'Outside of the US' &&*/}
          {/*  <>*/}
          {/*    <RegistrationField name="country" label="Country" isError={isFoundError && !state.country}*/}
          {/*                       isModal={isModal}*/}
          {/*                       disabled/>*/}
          {/*    <div className="input-field">*/}
          {/*      <div className={'input-field__input'}>*/}
          {/*        <CountryDropdown*/}
          {/*          classes="regionDropdown"*/}
          {/*          value={state.country}*/}
          {/*          onChange={(val) => setState({ ...state, country: val })}/>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div style={{ marginTop: -40 }}/>*/}
          {/*  </>}*/}
          {/*<RegistrationField name="state" label="State" isError={isFoundError && !state.state} isModal={isModal} disabled/>*/}
          {/*<div className="input-field">*/}
          {/*  <div className={'input-field__input'}>*/}
          {/*    <RegionDropdown*/}
          {/*      classes="regionDropdown"*/}
          {/*      country={'United States'}*/}
          {/*      value={state.state}*/}
          {/*      onBlur={()=>console.log('blur')}*/}
          {/*      onChange={(val) => setState({ ...state, state: val })}/>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div style={{ marginTop: -40 }}/>*/}
          {/*<InputField>*/}
          {/*  <input name="iamover18" type="checkbox" className="form-check-input" id="iamover18" required />*/}
          {/*  <label className="form-check-label" htmlFor="iamover18">I understand that by entering my email and phone*/}
          {/*      number, I consent to receive emails and/or SMS about Grouport, promotions, reminders about my account,*/}
          {/*      and schedule updates.*/}
          {/*      Message and data rates may apply. <br /><br /> Message frequency varies. Reply HELP for help. Reply STOP to*/}
          {/*      opt out from SMS messages. I am over 18 and agree to Grouport’s <a className="gr_link"*/}
          {/*      href="https://www.grouporttherapy.com/terms-services/terms-of-service"*/}
          {/*      target="_blank">Terms of Service</a> &*/}
          {/*      <a className="gr_link"*/}
          {/*      href="https://www.grouporttherapy.com/terms-services/privacy-policy"*/}
          {/*      target="_blank">Privacy Policy</a>.*/}
          {/*  </label>*/}
          {/*</InputField>*/}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ fontSize: '12px', marginBottom: '6px', maxWidth: '60%', paddingLeft: '20px' }}>
              <input name="iamover18" type="checkbox" className="form-check-input" id="iamover18" checked={state.iam18} onChange={(el) => setState({ ...state, iam18: el.target.checked })} />
              <label className='form-check-label' htmlFor="iamover18">
                {isFoundError && !state.iam18 ? <span style={{fontSize: '13px'}} className='error-color'>To continue, please indicate that you read and agree to the below terms and policies. <br /></span> : ''}
                I understand that by entering my email and phone number, I consent to receive emails and/or SMS about Grouport, promotions, & reminders about my account. Message and data rates may apply. Message frequency varies. Reply HELP for help. Reply STOP to opt out from SMS messages. I am over 18 and agree to Grouport’s <a className="gr_link"
                                                                                   href="https://www.grouporttherapy.com/terms-services/terms-of-service"
                                                                                   target="_blank">Terms of Service</a> & <a className="gr_link"
                   href="https://www.grouporttherapy.com/terms-services/privacy-policy"
                   target="_blank">Privacy Policy</a>.
              </label>
            </div>
          </div>
          <div className="viewButton">
            {spinner ? <div className="btnStepOne spinnerView">
              <ClipLoader color={'white'} loading={true} cssOverride={override} size={10}/>
            </div> : <div className="btnStepOne" onClick={onClickContinue}>Continue</div>}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModal}
        onAfterOpen={'green'}
        onRequestClose={() => setIsModal(false)}
        style={customStyles}>
        <div className="archive-therapist-container">
          <div className="archive-therapist-container__title">
            Email is already in use for an existing account. Try another email to sign up.
          </div>
          <div>
            <button className="btn-form" style={{ marginLeft: 0 }} onClick={closeModal}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'white',
}

const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    minHeight: '150px',
    backgroundColor: 'white',
    borderRadius: '15px'
  },
}

StepOne.propTypes = {
  changeHandler: PropTypes.func,
  setState: PropTypes.func,
  onClickContinue: PropTypes.func,
  setIsModal: PropTypes.func,
  closeModal: PropTypes.func,
  state: PropTypes.object,
  isFoundError: PropTypes.bool,
  isModal: PropTypes.bool,
  spinner: PropTypes.bool,
}
