import React, {CSSProperties, useEffect, useState} from 'react'
import './style.css'
import {useMutation} from "@tanstack/react-query";
import {checkAcuityCoupon} from "../../services/auth";
import ClipLoader from "react-spinners/ClipLoader";

const CouponField = ({choiceDate, setAppliedCouponCode, checkCoupon, couponData, appliedCouponCode}) => {
  const [couponCode, setCouponCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [isApplyBtn, setIsApplyBtn] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  }

  const successSVG = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="q_69565" data-name="q 69565" transform="translate(0)">
      <g id="q_69886" data-name="q 69886" transform="translate(0)">
        <g id="q_69529" data-name="q 69529">
          <circle id="w_3179" data-name="w 3179" cx="12.5" cy="12.5" r="12.5" fill="#3d5581bf"></circle>
        </g>
        <g id="q_69530" data-name="q 69530" transform="translate(6.786 7.125)">
          <g id="Component_icon_ic_Chevron_Down_Copy" data-name="Component/icon/ic_Chevron Down Copy">
            <path id="Checklist" d="M2.245,4.083A1.225,1.225,0,0,0,.38,4.1,1.59,1.59,0,0,0,.392,6.174l3.35,3.684a1.231,1.231,0,0,0,1.943-.11L11.125,2.4A1.586,1.586,0,0,0,10.948.336a1.228,1.228,0,0,0-1.856.2L4.569,6.639Z" fill="#fff"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>;

  const changeHandler = (value) => {
    setIsError(false);
    setCouponCode(value);
  }

  useEffect(() => {
    if (couponData) {
      if (couponData.data.data.item.valid) {
        setAppliedCouponCode(couponData.data.data.item.name);
        setIsApplyBtn(false);
      } else {
        setIsError(true);
        setAppliedCouponCode('');
        setIsApplyBtn(true)
      }

      setSpinner(false);
    }
  }, [couponData]);

  const onClickCheckCoupon = async (event) => {
    event.preventDefault();

    if (appliedCouponCode) {
      setCouponCode('');
      setAppliedCouponCode('');
      setIsApplyBtn(true);
    } else {
      setSpinner(true);
      await checkCoupon({typeId: choiceDate?.id, couponCode});
    }
  }

  return (
    <div className="input-field coupon-field">
      <div className='input-field__input'>
        <div className='couponInputWrap'>
          <input className={isError ? 'inputCard couponError' : 'inputCard'} value={couponCode} placeholder={'Enter code'}
                 onChange={(e) => changeHandler(e.target.value)}
          />
          {appliedCouponCode ? <div className="appliedIcon">{successSVG}</div> : null}
          {isError ? <span className='couponErrorMessage'>The coupon is invalid</span> : null}
        </div>
        <a className='apply_btn' onClick={onClickCheckCoupon}>
          {spinner ? '' : (isApplyBtn ? 'Apply' : 'Remove')}
          {spinner
            ? <ClipLoader color={'white'}
                          loading={true}
                          cssOverride={override}
                          size={10}/>
            : null
          }
        </a>
      </div>
    </div>
  )
}

export default CouponField
