import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useMutation } from '@tanstack/react-query'
//components
import { MainModal } from './MainModal'
//utils services
import { authWithToken, createUserStripe, getDateAcuity } from '../../services/auth'
import { StorageClearAll, StorageGetItem, StorageSetItem } from '../../utils/storage'
import { FROM_ANOTHER_WEB, STORED_ACCESS_TOKEN } from '../../utils/constants'
//styles images
import './style.css'

export const MainModalContainer = () => {
  const { data, error, isError, mutate } = useMutation(authWithToken)
  const { data: getDate, isError: getDateIsError, mutate: getDateMutate } = useMutation(getDateAcuity)
  const { data: dataCreate, isError: isErrorCreate, mutate: mutateCreate } = useMutation(createUserStripe)
  const [isConfirm, setIsConfirm] = useState(false)
  const [reaction, setReaction] = useState(false)
  const [isSavedToken, setIsSavedToken] = useState(false)
  const [step, setStep] = useState(0)
  const [isFromAnotherWeb, setIsFromAnotherWeb] = useState('')
  const [choiceDate, setChoiceDate] = useState({})
  const query = window?.location?.search
  const queryObj = queryString.parse(query)

  useEffect(() => {
    choiceStep()
  }, [])

  useEffect(() => {
    if (isError) {
      //smth wrong with token or id
      if (error.message === 'Request failed with status code 403') {
        window.location.href = process.env.REACT_APP_DOMEN
      }
    }
  }, [isError])

  useEffect(() => {
    if (data && !isError) {
      signInFunc()
    }
  }, [data])

  useEffect(() => {
    if (isConfirm) {
      rmStorage()
    }
  }, [isConfirm])

  useEffect(() => {
    if (step === 3) {
      mutateCreate()
    }
  }, [step])

  useEffect(() => {
    if (isSavedToken) {
      checkToken()
    }
  }, [isSavedToken])

  const checkToken = async () => {
    const refreshToken = await StorageGetItem(STORED_ACCESS_TOKEN)
    if (refreshToken) {
      getDateMutate()
    }
  }

  const signInFunc = async () => {
    setReaction(data.data.data.item?.reaction)
    await StorageSetItem(STORED_ACCESS_TOKEN, data.data.data.item.token)
    setIsSavedToken(true)
  }

  const onClickButtonWidget = (number) => {
    setStep(number)
  }

  const onClickConfirm = (stepNum) => {
    setStep(stepNum)
  }

  const confirmFunc = () => {
    setIsConfirm(true)
  }

  const choiceStep = async () => {
      const tokenStorage = await StorageGetItem(STORED_ACCESS_TOKEN)
      if (tokenStorage) {
        const fromAnotherWebStorage = await StorageGetItem(FROM_ANOTHER_WEB)
        if (fromAnotherWebStorage==='true') {
          setIsFromAnotherWeb(true)
        }
        setStep(2)
        getDateMutate()
      } else {
        if (queryObj.token && queryObj.id) {
          setStep(2)
          mutate({ token: queryObj.token, id: queryObj.id })
          await StorageSetItem(FROM_ANOTHER_WEB, 'true')
          setIsFromAnotherWeb(true)
        } else {
          setStep(1)
        }
      }
  }

  const rmStorage = async () => {
    await StorageClearAll()
  }

  return (
    <MainModal
      isConfirm={isConfirm}
      step={step}
      onClickConfirm={onClickConfirm}
      setIsSavedToken={setIsSavedToken}
      choiceDate={choiceDate}
      setChoiceDate={setChoiceDate}
      getDateIsError={getDateIsError}
      getDate={getDate}
      confirmFunc={confirmFunc}
      isErrorCreate={isErrorCreate}
      dataCreate={dataCreate}
      reaction={reaction}
      isFromAnotherWeb={isFromAnotherWeb}
      onClickButtonWidget={onClickButtonWidget}
    />
  )

}


