export function getTherapistNameAndLicensesFromString(string) {
  let therapistName = stringBetweenString('with', string, ',');
  therapistName.trim();

  let licenses = stringBetweenString(',', string);
  licenses.trim();

  return {
    name: therapistName,
    licenses,
  }
}

function stringBetweenString(startStr, str, endStr = null) {
  const pos = str.indexOf(startStr) + startStr.length;
  const endPos = endStr ? str.indexOf(endStr, pos) : str.length + 1;
  return str.substring(pos, endPos);
}