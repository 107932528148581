import httpClient from '../utils/http'

export const registration = async (data) => {
  const api = await httpClient()
  const res = await api.post(`/customer/registration?webflow=true&series=true`, {
    email: data.email,
    firstname: data.firstname,
    lastname: data.lastname,
    password: 'AKjdjiw34nmdksuery',
    assessmentfunnel: false
  })
  return res
}
export const addSeries = async (data) => {
  const api = await httpClient()
  const res = await api.post(`/customer/add-series-data?web_wait_list=true&landing=true`, {
    age: +data.age,
    phone: data.phone,
    state: data.state,
    country: data.country
  })
  return res
}

export const getCountries = async () => {
  const api = await httpClient()
  const res = await api.get(`/countries`)
  return res
}

export const authWithToken = async (data) => {
  const api = await httpClient()
  const res = await api.post(`/${data.id}/series-login`, {
    token: data.token
  })
  return res
}

export const checkEmail = async (data) => {
  const api = await httpClient()
  const res = await api.post(`/customer/is-exists`, {
    email: data?.email
  })
  return res
}

export const login = async (data) => {
  const api = await httpClient()
  const res = await api.post(`customer/open-login-lead`, {
    email: data?.email,
    reset: true,
    password: 'AKjdjiw34nmdksuery',
    firstName: data.firstname,
    lastName: data.lastname,
  })
  return res
}

export const editProfile = async (data, setSpinner) => {
  const body = data.data
  const api = await httpClient()
  const res = await api.patch(`customer/update-base-registration-data`, body)
  setSpinner(false)
  return res
}

export const addSeriesAcuity = async (data) => {
  const api = await httpClient()
  const dateTime = new Date(data?.choiceDate?.dateTime * 1000)
  const res = await api.patch(`/customer/add-series-acuity`, {
    acuityTypeId: data?.choiceDate?.id,
    dateTime
  })
  return res
}

export const addSeriesAcuity2 = async (data) => {
  const api = await httpClient()
  const res = await api.post(`/customer/add-series-data?landing=true`, {
    groupId: data?.choiceDate?.id,
    groupName: data?.choiceDate?.name
  })
  return res
}

export const getDateAcuity = async () => {
  const api = await httpClient()
  const res = await api.get(`/customer/get-series-acuity-dates`)
  return res
}

export const createUserStripe = async () => {
  const api = await httpClient()
  const res = await api.patch(`/customer/charge-series-customer`)
  return res
}

export const confirmPayment = async (data) => {
  const api = await httpClient()
  const res = await api.patch(`/customer/confirm-series-payment`, data)
  return res
}

export const checkAcuityCoupon = async ({typeId, couponCode}) => {
  const api = await httpClient()
  const res = await api.get(`/customer/acuity-check-coupon`, {params: {typeId, couponCode}})
  return res
}

export const applyAcuityCoupon = async ({typeId, couponCode}) => {
  const api = await httpClient()
  const res = await api.patch(`/customer/apply-acuity-coupon`, null, {params: {typeId, couponCode}})
  return res
}





