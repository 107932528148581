import React, {CSSProperties, useEffect, useState} from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import PropTypes from 'prop-types'
import {getTherapistNameAndLicensesFromString} from "../../utils/helper";
import moment from "moment-timezone";

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
}

export const StepTwo = ({ viewArray, onClickNext, choiceDate, onClickChoice, choiceError }) => {
  const [showStep, setShowStep] = useState(1);
  const [showArray, setShowArray] = useState([]);
  const clientTzName = moment.tz.guess();

  const onClickSeeOther = (step) => {
    setShowStep(step);
  }

  useEffect(() => {
    setShowArray(viewArray.slice(0, 3));
  }, [viewArray])

  useEffect(() => {
    switch (showStep) {
      case 1:
        setShowArray(viewArray.slice(0, 3));
        break;
      case 2:
        setShowArray(viewArray.slice(0, 7));
        break;
      default:
        setShowArray(viewArray);
    }
  }, [showStep])

  const renderViewText = (e) => {
    const therapistData = getTherapistNameAndLicensesFromString(e.name);
    let startWithTimeZone = moment.unix(e.dateTime).tz(clientTzName);
    let timestring = startWithTimeZone.format('h:mm A')  + ' - ' + startWithTimeZone.add(e.duration,'minutes').format('h:mm A z');

    return (
      <div key={e.id} className={choiceDate?.id === e.id ? 'viewText viewTextSelected' : choiceError ? 'error viewText' : 'viewText'}
           onClick={() => onClickChoice(e)}>
        <div className='leftSide'>
          <div className="imgContainer">
            <img src={e.image ?? null} alt="Therapist avatar"/>
          </div>
          <div className="userNameLicense">
            <div>{therapistData.name}</div>
            <div>{therapistData.licenses}</div>
          </div>
        </div>
        <div className='rightSide'>
          <div className="groupType">DBT</div>
          <div className="userDescribe">{e.description}</div>
          <div className="groupTime">
            <span className="weekday">{startWithTimeZone.format('dddd') + 's'}</span>
            <span className="time">{timestring}</span>
          </div>
          <div className='selectedCircle'>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 405.272 405.272">
              <path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836   c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064   c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"></path>
            </svg>
          </div>
        </div>
      </div>
    )
  }

  if (showArray.length > 0) {
    return (
      <div className="mainViewStepTwo">
        {showArray?.map(e => renderViewText(e))}
        {choiceError && <div className='errorText'>
          Please choose a group
        </div>}
        {showStep === 1 && viewArray.length > 1 && <div className="viewButton pb-0">
          <div className="choose_other_time show_other" onClick={() => onClickSeeOther(2)}>
            Time doesn’t work? <a className="choose_another_time">See other group times</a>
          </div>
        </div>}
        {showStep === 2 && viewArray.length > 4 && <div className="viewButton pb-0">
          <div className="choose_other_time show_other" onClick={() => onClickSeeOther(3)}>
            Load more
          </div>
        </div>}
        <div className="viewButton">
          <div className="btn" onClick={onClickNext}>Continue</div>
        </div>
      </div>
    )
  }

  return (
    <div style={{
      width: 100, height: 100, display: 'flex',
      justifyContent: 'center', alignItems: 'center'
    }}>
      <ClipLoader
        color={'#3D5581'}
        loading={true}
        cssOverride={override}
        size={30}/>
    </div>
  )
}

StepTwo.propTypes = {
  viewArray: PropTypes.array,
  onClickNext: PropTypes.func,
  onClickChoice: PropTypes.func,
  choiceDate: PropTypes.object,
}
