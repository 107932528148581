import React, { CSSProperties, useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMutation } from '@tanstack/react-query'
import {
  addSeriesAcuity,
  addSeriesAcuity2,
  applyAcuityCoupon,
  checkAcuityCoupon,
  confirmPayment,
  editProfile
} from '../../services/auth'
import ClipLoader from 'react-spinners/ClipLoader'
import PropTypes from 'prop-types'
import { StorageGetItem } from '../../utils/storage'
import { IS_EDIT } from '../../utils/constants'
import './style.css'
import CouponField from "../CouponField/CouponField";

export const StepThree = ({ confirmFunc, choiceDate, dataCreate, isErrorCreate }) => {
  const { mutate: addMutate, data: addData, isError: isErrorAddSeriesAcuity } = useMutation(addSeriesAcuity)
  const { mutate: addMutate2, data: addData2, isError: isErrorAddSeriesAcuity2 } = useMutation(addSeriesAcuity2)
  const { isError: isErrorEdit, mutate: mutateEdit } = useMutation(editProfile)
  const { data, isError, mutate } = useMutation(confirmPayment)
  const [clientSecret, setClientSecret] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [appliedCouponCode, setAppliedCouponCode] = useState('')
  const { mutate: checkCoupon, data: couponData, isError: isErrorCheckCoupon } = useMutation(checkAcuityCoupon)
  const { mutateAsync: applyCoupon, data: applyCouponData } = useMutation(applyAcuityCoupon)
  const initPrice = 299 * 100;
  const [paymentPrice, setPaymentPrice] = useState(initPrice / 100);
  const stripe = useStripe()
  const elements = useElements()
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'white',
  }

  useEffect(() => {
    if (dataCreate && !isErrorCreate) {
      setClientSecret(dataCreate.data.data.item.clientSecret)
    }
  }, [dataCreate, isErrorCreate])

  useEffect(() => {
    if (data && !isError) {
      confirmFunc()
    }
  }, [data, isError])

  useEffect(() => {
    if (addData2 && addData) {
      successPayment()
    }
  }, [addData2, addData])

  useEffect(() => {
    if (isErrorAddSeriesAcuity || isErrorAddSeriesAcuity2 || isErrorEdit) {
      setSpinner(false)
    }
  }, [isErrorAddSeriesAcuity, isErrorAddSeriesAcuity2, isErrorEdit])

  useEffect(() => {
    if (appliedCouponCode) {
      if (couponData) {
        let percentageOff = couponData.data.data.item.percentageOff;
        let amountOff = couponData.data.data.item.amountOff * 100;
        let newAmount = 0;
        if (amountOff) {
          newAmount = Math.round((initPrice - amountOff) / 100, 2);
        }

        if (percentageOff) {
          newAmount = Math.round(initPrice - initPrice * percentageOff / 100, 2)
        }

        if (newAmount) {
          setPaymentPrice(newAmount);
        }
      }
    } else {
      setPaymentPrice(initPrice / 100)
    }
  }, [appliedCouponCode])

  const onClickPay = async (event) => {
    event.preventDefault()
    setSpinner(true)
    addMutate({ choiceDate })
    addMutate2({ choiceDate })
    const isEdit = await StorageGetItem(IS_EDIT)
    if (isEdit) {
      let state = {
        groupId: choiceDate?.id,
        groupName: choiceDate?.name
      }
      mutateEdit({ data: state, setSpinner: setSpinner })
    }

  }

  const successPayment = async () => {
    if (!stripe || !elements) {
      setSpinner(false)
      return
    }
    setSpinner(true)
    try {
      if (appliedCouponCode) {
        await applyCoupon({typeId: choiceDate?.id, couponCode: appliedCouponCode});
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      if (result?.paymentIntent?.id) {
        const dataConfirm = {
          pi: result?.paymentIntent?.id
        }
        const dataGclid = JSON.parse(sessionStorage.getItem('gclid'));
        if (dataGclid?.value) {
          dataConfirm.gclid = dataGclid?.value;
        }

        addMutate(dataConfirm)
        mutate(dataConfirm)
      } else {
        setSpinner(false)
      }
    } catch (err) {
      setSpinner(false)
      console.log(err)
    }
  }

  return (
    <div className="mainViewStepThree">
      <div className='summary_block' style={{ flex: 1 }}>
        <h2>Summary</h2>
        <div className="mT16 priceText text-table">
          <div className="row">
            <div className="textpart">DBT Skills Group (12 sessions x $25/session)</div>
            <div className="pricepart">${initPrice / 100}</div>
          </div>
          {appliedCouponCode
            ? <div className="row discount">
                <div className="textpart">First billing period discount</div>
                <div className="pricepart">-${initPrice / 100 - paymentPrice}</div>
              </div>
            : null
          }
          <div className='hr-custom'></div>
          {appliedCouponCode
            ? <div className="row savings">
              <div className="textpart">Your savings</div>
              <div className="pricepart">-${initPrice / 100 - paymentPrice}</div>
            </div>
            : null
          }
          <div className="row total">
            <div className="textpart">Total due today</div>
            <div className="pricepart">${paymentPrice}</div>
          </div>
        </div>
        <div className="blue-color">Apply a coupon</div>
        <div>
          <CouponField
            choiceDate={choiceDate}
            setAppliedCouponCode={setAppliedCouponCode}
            checkCoupon={checkCoupon}
            couponData={couponData}
            appliedCouponCode={appliedCouponCode}
          />
        </div>
      </div>
      <div className="viewFirstTable">
        <div style={{ flex: 1 }}>
          <div className="mT16">Credit Card Number</div>
          <div className="mT16">
            <div className="inputCard">
              <CardElement />
            </div>
          </div>
        </div>
      </div>
      <div className="viewButtonPay">
        <div className='textAboutCondition'>You will be billed upfront for the first 3 months DBT Core Principles course and then $100 at the 
          start of each month-long billing cycle thereafter. You may cancel your subscription at any time.
        </div>
        <a
          style={{ fontSize: 14 }}
          className="main-menu__link"
          target="_blank"
          href="https://www.grouporttherapy.com/terms-services/grouport-series-terms-and-services"
          rel="noreferrer"
        >
          Grouport Series Terms and Conditions
        </a>
        <div className='buttonPay'>
          {spinner ? <div className="btn spinnerView"><ClipLoader color={'white'} loading={true} cssOverride={override}
                                                                  size={10}/>
            </div> :
            <div className="btn" onClick={onClickPay}>Pay & Confirm</div>}
        </div>
      </div>
    </div>
  )
}

StepThree.propTypes = {
  confirmFunc: PropTypes.func,
  choiceDate: PropTypes.object,
  dataCreate: PropTypes.object,
  isErrorCreate: PropTypes.bool
}
